

export const editorConfig_2 = () => {
  return {
    toolbarGroups: [

    // { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    // { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
    // { name: 'styles' },
    // { name: 'colors' },
    // { name: 'tools' },
    // { name: 'insert' },
    // { name: 'links' }

        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ] },
        { name: 'forms' },
        '/',
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
        { name: 'links' },
        { name: 'insert' },
        '/',
        { name: 'styles' },
        { name: 'colors' },
        { name: 'tools' },
        { name: 'others' }

    ],

    language: "en",
    height: 400,
    width: 950,
    uiColor: '#62c1de',

    extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',

    // Adding drag and drop image upload.
   // extraPlugins: 'print,format,font,colorbutton,justify,uploadimage',
    // extraPlugins: 'video',

    filebrowserBrowseUrl: window.location.protocol + '//' + window.location.host + '/public/admin/ckeditor/ckfinder/ckfinder.html',
    filebrowserImageBrowseUrl: window.location.protocol + '//' + window.location.host + '/public/admin/ckeditor/ckfinder/ckfinder.html?Type=Images',
    // filebrowserFlashBrowseUrl : 'http://'+window.location.host+ '/public/ckeditor/ckfinder/ckfinder.html?Type=Flash',
    filebrowserUploadUrl: 'http://' + window.location.host + '/public/ckeditor/ckfinder/core/connector/aspx/connector.aspx?command=QuickUpload&type=Files',
    filebrowserImageUploadUrl: 'http://' + window.location.host + '/public/ckeditor/ckfinder/core/connector/aspx/connector.aspx?command=QuickUpload&type=Images',
    filebrowserFlashUploadUrl: 'http://' + window.location.host + '/public/ckeditor/ckfinder/core/connector/aspx/connector.aspx?command=QuickUpload&type=Flash',

    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'PasteFromWord'
  }
};

