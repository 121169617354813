



export const editorConfigVideo_En = {

   toolbarGroups : [
                    
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ] },
        { name: 'forms' },
        '/',
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
        { name: 'links' },
         '/',
        { name: 'insert'},
       
        '/',
        { name: 'styles' },
        { name: 'colors' },
        { name: 'tools' },
        { name: 'others' }

       

                    ],
    // toolbar_Pure :
    //   [
    //      {name: 'insert', items: [ 'Video']}
    //  ],
 // removeButtons: 'NewPage,Print,Save,Templates,Replace,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,Anchor,Flash,Smiley,PageBreak,ShowBlocks,About,Language,Iframe,Image',
  removeButtons: 'NewPage,Print,Save,Templates,Replace,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,HiddenField,CreateDiv,Anchor,Language',


  language: "en",
  height: 400,
  width: 950,
  uiColor: '#62c1de',

  //extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
 // extraAllowedContent :'area[!shape,!coords,!href,!target,alt];' +
 //    'map[!name];' + 'img[!usemap,!border,!id];style;script(*)[*]{*}',

 allowedContent:true,
  disableNativeSpellChecker:false,
  disallowedContent:"",
 extraAllowedContent :"*(*);*{*};*[*];",
    
  // Adding drag and drop image upload.
   extraPlugins: 'video,html5audio',

  filebrowserBrowseUrl: window.location.protocol + '//' + window.location.host + '/layout/ckfinder/ckfinder.html',
  filebrowserImageBrowseUrl: window.location.protocol + '//' + window.location.host + '/layout/ckfinder/ckfinder.html?Type=Images',
   filebrowserFlashBrowseUrl : window.location.protocol + '//' + window.location.host + '/layout/ckfinder/ckfinder.html?Type=Flash',
   filebrowserVideoBrowseUrl : window.location.protocol + '//' + window.location.host + '/layout/ckfinder/ckfinder.html?Type=Video',

  filebrowserUploadUrl:  window.location.protocol + '//' + window.location.host + '/layout/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files',
  filebrowserImageUploadUrl: window.location.protocol + '//' + window.location.host + '/layout/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images',
  filebrowserFlashUploadUrl: window.location.protocol + '//' + window.location.host +  '/layout/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Flash',
  filebrowserVideoUploadUrl: window.location.protocol + '//' + window.location.host +  '/layout/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Video',
  //removeDialogTabs: 'image:advanced;link:advanced',
  //removeButtons: 'PasteFromWord'

}

