<template>
  <div
    class="app header-fixed sidebar-fixed aside-menu-fixed aside-menu-hidden"
  >
    <AdminHeader v-if="name.length>0 && is_loading" :name="name" />

    <div class="app-body">
      <!-- SIDE -->
      <sidebar  v-if="name.length>0" />
      <!-- Main content -->

      <div class="main" style="margin-top: 0px" v-if="is_loading">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/admin/sidebar.vue";
import AdminHeader from "@/components/admin/AdminHeader.vue";

//require("../assets/fontawesome/css/all.min.css");

import "@fortawesome/fontawesome-free/css/all.css";
import "sweetalert2/dist/sweetalert2.min.css";

require("../assets/admin/css/simple-line-icons.css");
require("../assets/admin/css/style.css");


import { editorConfig } from "@/components/library/editorConfig.js";
import { editorConfigVideo } from "@/components/library/editorVideo.js";
import { editorConfigVideo_En } from "@/components/library/editorConfigVideo_En.js";


import * as MyFn from "@/components/library/editorConfig_2.js";



import axios from 'axios';



window.$=window.jQuery=require("jquery");

import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule,
      rules[rule]);
});



import { messages } from 'vee-validate/dist/locale/vi.json';

for (let [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
    message: messages[rule] 
  });
}


export default {
  name: "Admin",
  data(){
    return {
      name:"",
      is_loading:false,
      Customer:null,
      Role:null,
      has_load:true,
      myscript:[],
      extendLoaded:false
    }
  },
  components: {
    sidebar,
    AdminHeader,
  },
  created(){

                 window.editorConfigVideo = editorConfigVideo;
                  window.editorConfigVideo_EN = editorConfigVideo_En;  
                    
                  window.editorConfig = editorConfig;
                  window.editorConfig_EN = MyFn.editorConfig_2();
                  window.editorConfig_ques = MyFn.editorConfig_2();
                  window.editorConfig_ques_EN = MyFn.editorConfig_2();
                  window.editorConfig_asome = MyFn.editorConfig_2();
                  window.editorConfig_asome_EN = MyFn.editorConfig_2();

     this.$session.start()
     if(!this.$session.has("isLogin")){
          
           if (localStorage.mylogin) {
             console.log("BUITIN", JSON.parse(localStorage.mylogin ) );
              this.$session.set("isLogin", JSON.parse(localStorage.mylogin ) );

           }

         
    
       
      }

      if(!this.$session.has("isLogin") ){

            this.$router.push({name:"admin.login"});

      }else{


         localStorage.is_loading=1;

         var user= this.$session.get("isLogin");
         user['language'] = this.$route.query.lang!=undefined ? this.$route.query.lang:  "vn";
         window.my_header= user;



             // console.log("USERNAME HEADER: ",user);
      
        let p1=axios.get(window.my_api +"api/user/get-user-by-id?userId="+user.userID,
                         {                          headers: {
                                                            language: this.$i18n.locale
                                                          }
                          }
                  
        )
             .then( (res)=>{
                   
              

                    this.$session.set("Customer", res.data.data);
                    this.name=res.data.data.username;

                     localStorage.is_loading=2;
                     localStorage.Customer=JSON.stringify(res.data.data);

                      this.Customer=res.data.data;
                
             });

         let p2=axios.get(window.my_api +"api/role/get-roles",
                         {                          headers: {
                                                            language: this.$i18n.locale
                                                          }
                          }
                  
        )
             .then( (res)=>{
                   
                 
                this.Role=res.data.data;
                    
                
             });

            Promise.all([p1,p2]).then(()=>{
               
                
                let  roleId = this.Role.filter( (n)=> n.id== this.Customer.roleId );
             
                this.$session.set("Role", roleId );
                localStorage.Roles=roleId[0].flagContent;

               
                this.loadAdmin();

                 this.is_loading=true;
            });

         //console.log("HEader",window.my_header);
      }

  },
  mounted() {

    
  },
  methods:{

    loadAdmin(){
     
        // let recaptchaCSS = document.createElement("link");
        //   recaptchaCSS.setAttribute("href", "/layout/admin/jquery.fancybox/fancybox/jquery.fancybox-1.3.4.css");
        //   recaptchaCSS.setAttribute("rel", "stylesheet");
        //    recaptchaCSS.setAttribute("type", "text/css");

        //   document.head.appendChild(recaptchaCSS);

          this.myscript=[
              // {
              //   src:"/layout/jquery.js?v="+Math.random().toString(),
              //   type:"text/javascript",
              //   callback:()=>(this.extendLoaded=true)
              // },

             
              {
                
                src:"/layout/admin/js/app.js",
                type:"text/javascript"
              },
               {
                
                src:"/layout/admin.js",
                type:"text/javascript"  
              },
               {
              
                src:"/layout/bootstrap.min.js",
                type:"text/javascript",
                
                
              },
             
          ];
        

            // let recaptchaScript3 = document.createElement("script");
            // recaptchaScript3.setAttribute("src", "/layout/ckeditor/ckeditor.js");
            // document.head.appendChild(recaptchaScript3);

            // let recaptchaScript4 = document.createElement("script");
            // recaptchaScript4.setAttribute("src", "/layout/ckeditor/function.js");
            // document.head.appendChild(recaptchaScript4);

           
    }
  },


   metaInfo(){
    return {
     
        title: "Admin | Bến xe Miền Đông ",
       
       // titleTemplate: '%s | Bến xe Miền Đông',
         meta: [
          { charset: 'utf-8' },
          // { name: 'viewport', content: 'width=1300' },
          { name: 'viewport', content:  "width=device-width, initial-scale=1" },
          { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
         
        ],

        htmlAttrs:{
           lang:'vn'
        },

        
         script:this.myscript,
          link:[
             {rel:"stylesheet",href:"/layout/admin/jquery.fancybox/fancybox/jquery.fancybox-1.3.4.css"}
          ]
       
      }
  }
};
</script>



