<template>
    <div class="app-header navbar">
        <button class="navbar-toggler mobile-sidebar-toggler d-lg-none mr-auto" type="button">☰</button>
        <a style="background-image:;background-size:auto 93%" class="navbar-brand" href="/" target="_black">
            <img src="../../assets/logo.png" style="width: 90%">
        </a>
        <button class="navbar-toggler sidebar-minimizer d-md-down-none" type="button">☰</button>
       
        <ul class="nav navbar-nav d-md-down-none" >
          
           
          
            <li class="nav-item px-2" >
                
                 <router-link  class="btn btn-sm " :class="{'btn-info':is_sidebar!=1,'btn-primary':is_sidebar==1}"  to="/admin/general/station">
                              <i class="fa fa-car" aria-hidden="true"></i>
                              Quản lý Bến xe 

                              
                </router-link>
            </li>
          
            

            <li class="nav-item px-2" >
               

                 <router-link class="btn btn-sm  " :class="{'btn-info':is_sidebar!=2, 'btn-primary':is_sidebar==2}"   to="/admin/general/info">
                                     <i class='fa fa-info'></i>
                                      Quản lý thông tin website 
                </router-link>
            </li>
          
          
            
        </ul>
        <ul class="nav navbar-nav ml-auto">
             <li class="nav-item ">

               
                <a class="nav-link "  href="/admin/contact/lists" role="button" aria-haspopup="true" aria-expanded="false">

                     
                        <i class="fas fa-comment-alt-exclamation p-1 bg-danger"></i>

                       
                    
                </a>
               
                
            </li>   

         



            <li class="nav-item ">
                <a class="nav-link nav-link" data-toggle="dropdown" href="" role="button" aria-haspopup="true" aria-expanded="false">
                   
                    <span class="d-md-down-none"> {{name}} </span> |
                </a>
                
            </li>

             <li class="nav-item ">
                <div class="nav-link  nav-link"  @click="Logout">
                  
                    <span class="d-md-down-none"> thoát </span>
                </div>
                
            </li>
        </ul>
     

    </div>
</template>

<script>





export default {
    data(){
        return {
            is_sidebar:2,
            isRole:0,
            Role:null
            
        }
    },
   props:['name'],
   mounted(){
    
       

              let Customer= this.$session.get("Customer");
             
              this.isRole=Customer.isRole;



           

        
   },
   methods:{
    Logout(){
         this.$session.destroy();
         this.$router.push({name:"admin.login"});
    }
   },
   watch:{
        '$route'(to){
            this.is_sidebar = to.meta.is_side || 2;
           
        }
  }
}
</script>
